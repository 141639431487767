<template>
  <div class="container contact-header">
    <div class="row" data-aos="fade-up" data-aos-duration="1500">
      <div class="col-12 col-md-6">
        <img src="@/assets/img/contact-illustratie@2x.png" class="img-fluid" />
      </div>
      <div class="col">
          <h2>Neem contact met ons op!</h2>
          <p>
            Heb je vragen? Neem gerust contact met ons op! Dit kan door ons te mailen op onderstaand e-mailadres of door het contactformulier in te vullen.
          </p>
        </div>
      </div>
  </div>

  <div class="container contact-sectie-2">
    <div class="row">
      <div class="col-12 col-sm-7 contact-form order-2 order-sm-1">
        <!-- contactformulier component -->
          <contact-form></contact-form>
      </div>
      <div class="col contact-gegevens order-1 order-sm-2">
        <h2>Contact gegevens</h2>
        <ul>
          <li>
            <img src="@/assets/img/email.png" class="img-fluid" /><a
              href="mailto:contact@liefslune.nl"
              >contact@liefslune.nl</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm.vue";

export default {
  name: "App",
  components: {
    ContactForm,
  },
};
</script>
